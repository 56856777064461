import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { OAuthPopup, useOAuth2 } from './components/Auth';

const Home = lazy(() => import('./pages/Home'));
const ProtoformSynthesis = lazy(() => import('./pages/ProtoformSynthesis'))

function App() {
  const { data, loading, error, getAuth } = useOAuth2({
    authorizeUrl: "https://oauth.battle.net/authorize",
    clientId: "ef11e8a2301b488ea01645d7ea4568be",
    redirectUri: `${document.location.origin}/callback`,
    scope: "wow.profile",
    responseType: "code",
    exchangeCodeForTokenServerURL: "https://dwfysuyd3jitayspnbu4xtu6ui0msiyw.lambda-url.eu-west-1.on.aws/",
    exchangeCodeForTokenMethod: "POST",
    onSuccess: (payload) => console.log("Success", payload),
    onError: (error_) => console.log("Error", error_)
  });

  const isLoggedIn = Boolean(data?.access_token); // or whatever...

  let loginRender = (
    <button style={{ margin: "24px" }} type="button" onClick={() => getAuth()}>
      Login
    </button>
  )
  
  if (error) {
    loginRender = <div>Error</div>;
  }

  if (loading) {
    loginRender = <div>Loading...</div>;
  }

  if (isLoggedIn) {
    loginRender = <pre>Logged In</pre>;
  }

  return (
    <BrowserRouter>
      <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/protoform-synthesis">Protoform Synthesis</Link>
          </li>
          <li>
            {loginRender}
          </li>
        </ul>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<OAuthPopup />} path="/callback" />
          <Route path="/" element={<Home />} />
          <Route path="protoform-synthesis" element={<ProtoformSynthesis data={data}/>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
